<template>
<Card>
    <template #content>
		<p>Las lecturas han sido procesados con éxito.</p>
        <Divider/>
		<div>
			<p>Tipo: {{ tipo }}</p>			
			<p>Registros Procesados: {{ procesados }}</p>			
			<p>Total: {{moneda_simbolo}} {{formatCurrency(total_procesado)}}</p>						
		</div>      		    
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Continuar" @click="finalizar" class="p-button-warning" icon="pi pi-cog"/>
        </div>
    </template>
</Card>
</template>
<script>
import { CURRENT_APP_MODE, MDS_APP_MODE} from '../../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'ImpContResumen',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id_perfil: '',
					id_moneda: '',
					moneda_simbolo: '',
					tipo: 0,
					procesados: 0,
					total_procesado: 0.0,							
					errores: false,
					movs: [],					
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			secuencia: 1,
			procesandoDlg: false,
			id_moneda: this.formData.id_moneda,
			moneda_simbolo: this.formData.moneda_simbolo,	
			tipo: this.formData.tipo,
			procesados: this.formData.procesados,
			total_procesado: this.formData.total_procesado,					
			errores: this.formData.errores,
    }
    },
    methods: {
        finalizar() {
			this.$router.push('/');
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},			
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>